// Constants
import * as CREATE_PAYMENT_CONSTANTS from './constants';

// axios instance
import authInstance from '../../axios/authInstance';

// React taostify
import { toast } from 'react-toastify';
import { toastTheme } from '../../utils/toastTheme'


export const restCreatePaymentData = () => (dispatch) => {
    dispatch({ type: CREATE_PAYMENT_CONSTANTS.RESET_CREATE_PAYMENT_REDUCER });
}


export const createPaymentAndOrder = (fullPaymentData) => (dispatch) => {

    dispatch({ type: CREATE_PAYMENT_CONSTANTS.CREATE_PAYMENT_LOADING })

    try {
        authInstance.post('payment', fullPaymentData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                dispatch({ type: CREATE_PAYMENT_CONSTANTS.CREATE_PAYMENT_SUCCESS, payload: response.data });
            }).catch((error) => {
                toast.error('Something went wrong', toastTheme)
                dispatch({ type: CREATE_PAYMENT_CONSTANTS.CREATE_PAYMENT_ERROR, payload: error })
            });
    } catch (error) {
        dispatch({ type: CREATE_PAYMENT_CONSTANTS.CREATE_PAYMENT_ERROR, payload: error })
    }
}


export const threeDSAuthCall = (threeDsData) => async (dispatch, getState) => {

    // ====> getting all the necessary data to make the auth api call <=====
    const getBrowserWindowSize = () => '05';

    const { paymentRequestReducer: { order, ...restOfPaymentData } } = getState();

    const orderReference = threeDsData.orderReference;
    const paymentReference = threeDsData.reference;

    const { hostname } = window.location;
    let notificationURL = `https://${hostname}/api/notification/3ds2/challenge`;
    if (hostname === 'localhost') {
        // applies only for local env
        notificationURL = 'http://localhost:3000/api/notification/3ds2/challenge';
    }

    const data = {
        deviceChannel: 'BRW',
        threeDSCompInd: 'Y',
        notificationURL,
        browserInfo: {
            browserAcceptHeader: 'application/json',
            browserJavaEnabled: navigator.javaEnabled(),
            browserLanguage: window.navigator.language,
            browserTZ: new Date().getTimezoneOffset().toString(),
            browserUserAgent: window.navigator.userAgent,
            notificationURL,
            browserColorDepth: window.screen.colorDepth.toString(),
            browserScreenHeight: window.screen.height.toString(),
            browserScreenWidth: window.screen.width.toString(),
            browserJavascriptEnabled: true,
            challengeWindowSize: getBrowserWindowSize()
        }
    }
    // ====> finish getting all the necessary data to make the auth api call <====

    // Combine the necessary data in single object 
    const fullAuthCallBody = {
        ...restOfPaymentData,
        orderReference,
        paymentReference,
        data: data
    }

    try {
        dispatch({ type: CREATE_PAYMENT_CONSTANTS.AUTH_CALL_LOADING })
        authInstance.post('3ds2/authentication',
            fullAuthCallBody,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                dispatch({ type: CREATE_PAYMENT_CONSTANTS.AUTH_CALL_SUCCESS, payload: response.data })
            })
            .catch((error) => {
                dispatch({ type: CREATE_PAYMENT_CONSTANTS.AUTH_CALL_ERROR, payload: error })
            })
    } catch (error) {
        dispatch({ type: CREATE_PAYMENT_CONSTANTS.AUTH_CALL_ERROR, payload: error })
    }
}